<script lang="ts" async setup>

import { iViewPortalDirectusUser } from '~/interfaces'
const { $storageManagerFav } = useNuxtApp()
const storeContratti = useStoreCcnlContract()
// const { signOut, getSession } = useAuth()
// const storeUser = userCompose()
const _user = ref<iViewPortalDirectusUser | null>(null)

const listFavorite = ref<any[]>([])
onMounted(async () => {
	// console.log('UserAuthBox onMounted : ', userCompose().state.user)
	await useCompanyStore().loadUser()
	_user.value = useCompanyStore().getUser
	// console.log('UserAuthBox onMounted _user.value: ', _user.value)
	if (_user.value?.error === 'RefreshAccessTokenError') {
		console.error('UserAuthBox onMounted error: ', _user.value.error)
		useCompanyStore().logout()
	}
	// await storeUser.loadUser()
	// console.log('UserAuthBox storeUser.state.user: ', storeUser.state.user)

	/* const chekSession = await getSession()
	console.log('chekSession', chekSession)
	if (chekSession) {
		_user.value = chekSession.user as iViewPortalDirectusUser
	} */
})
onBeforeMount(() => {
	const list = $storageManagerFav.get('favoriteCCNL') as number[]
	console.log('list: ', list)

	if (list) {
		listFavorite.value.push(...list)
		console.log('listFavorite.value: ', listFavorite.value)
	}
})
const user = computed(() => useCompanyStore().getUser) // storeUser.state.user)
const FullNameToInitials = (fullName: string) => {
	if (!fullName) { return '' }
	const names = fullName.split(' ')
	let initials = ''
	names.forEach((name) => {
		initials += name.toLocaleUpperCase().substring(0, 1)
	})
	return initials
}
const GetCCNL = (id: number) => {
	const ccnl = storeContratti.getCcnlContractId(id)
	if (ccnl) {
		return ccnl// .nome_sub_settore
	}
	return undefined
}
const infoApp = appConfig.infoapp
/**
 * user:
 * {
 * "email": "info@beedweb.it", "sub": "0", "id": 0, "password": "", "type": { "id": 1, "type_name": "Admin" },
 * "company": { "id": 1, "name": "DelegaNoi", "address": "Via Prenestina Nuova 301/C, Palestrina (RM)", "contact_info": "info@deleganoi.it" },
 * "roles": [ { "role_id": 1, "role_name": "Admin", "description": "Amministratore con accesso completo" } ],
 * "permissions": [
 * 		{ "permission_id": 1, "permission_name": "VIEW_DATA", "description": "Può visualizzare i dati" },
 * 		{ "permission_id": 2, "permission_name": "EDIT_PROFILE", "description": "Può modificare il proprio profilo" },
 * 		{ "permission_id": 3, "permission_name": "ACCESS_API", "description": "Può accedere alle API" },
 * 		{ "permission_id": 4, "permission_name": "MANAGE_USERS", "description": "Può gestire altri utenti" } ],
 * 		"iat": 1698706397, "exp": 1701298397, "jti": "93a56a02-5512-4680-a02d-051b375d3e4c" }
 */
</script>
<template>
  <!-- <div class="grid">
		<div class="col-12">
		</div>
	</div> -->
  <!--  <div>
    <div class="grid">
      <div class="col-12">
        {{ JSON.stringify(user, null, 2) }}
      </div>
      <div class="col-12">
        <h1>Hi, {{ user?.email }}</h1>
      </div>
    </div>
    <div class="field-radiobutton">
      <Button label="LogOut" icon="pi pi-sign-out" @click="signOut()" />
    </div>
  </div> -->
  <div>
    <div v-if="!user">
      <h1>Not logged in</h1>
    </div>
    <div v-else class="card p-4">
      <!-- <h2>{{ user?.email }}</h2>
      <p>ID: {{ user?.id }}</p>
      <p>Company: {{ user?.company!.name }}</p>
      <p>Address: {{ user?.company!.address }}</p>
      <p>Contact Info: {{ user?.company!.contact_info }}</p>
      <h3>Roles</h3>
      <ul>
        <li v-for="(role, index) in user?.roles" :key="index">
          {{ role.role_name }} - {{ role.description }}
        </li>
      </ul>
      <h3>Permissions</h3>
      <ul>
        <li v-for="(permission, index) in user?.permissions" :key="index">
          {{ permission.permission_name }} - {{ permission.description }}
        </li>
      </ul>
      <Button label="LogOut" icon="pi pi-sign-out" @click="storeUser.logout()" /> -->

      <div id="slideover-7" class="surface-overlay absolute top-0 right-0 shadow-2 w-28rem h-full">
        <div class="flex flex-column p-4 h-full">
          <div class="flex align-items-center justify-content-start mb-4">
            <Icon name="line-md:account" size="20" class="text-green-400 mr-2" />
            <span class="text-2 text-xl font-medium">Profilo</span>

            <!-- <button
              class="p-button p-component p-button-icon-only p-button-rounded p-button-text p-button-plain"
              type="button"
            >
              <span class="p-button-icon pi pi-times" data-pc-section="icon" /><span
                class="p-button-label"
                data-pc-section="label"
              >&nbsp;</span>
            </button> -->
          </div>
          <div class="flex-auto overflow-y-auto mt-2">
            <div class="text-center mb-4">
              <!-- <img src="/user.png" style="width: 70px; height: 70px;"> -->
              <div class="m-4 mb-5 ">
                <span class="text-xl font-medium border-circle bg-primary p-3">
                  {{ FullNameToInitials(user?.fullName!) }}
                </span>
                <!-- <Badge :value="FullNameToInitials(user?.fullName!)" size="xlarge" severity="info" /> -->
              <!-- {{ FullNameToInitials(user?.fullName!) }} -->
              </div>
              <div class="text-2 mt-3 mb-2 text-xl font-medium">
                {{ user?.company!.name }}
              </div>
            </div>
            <!-- <div class="flex align-items-center mb-5">
              <a class="cursor-pointer flex flex-column flex-grow-1 align-items-center gap-2">
                <div
                  class="surface-100 hover:surface-200 transition-colors transition-duration-150 p-3 border-round-2xl p-ripple"
                  data-pd-ripple="true"
                ><i class="pi pi-comment text-xl text-600" />
                </div><span class="text-sm font-medium text-1">Message</span>
              </a>
              <a class="cursor-pointer flex flex-column flex-grow-1 align-items-center gap-2">
                <div
                  class="surface-100 hover:surface-200 transition-colors transition-duration-150 p-3 border-round-2xl p-ripple"
                  data-pd-ripple="true"
                ><i class="pi pi-phone text-xl text-600" />
                </div>
                <span class="text-sm font-medium text-1">Call</span>
              </a>
              <a class="cursor-pointer flex flex-column flex-grow-1 align-items-center gap-2" @click="useCompanyStore().logout()">
                <div
                  class="surface-100 hover:surface-200 transition-colors transition-duration-150 p-3 border-round-xl p-ripple"
                >
                  <i class="pi pi-sign-out text-xl text-600" />
                  <span />
                </div>
                <span class="text-sm font-medium text-1">Logout</span>
              </a>
            </div> -->
            <ul class="list-none p-0 m-0 pt-3">
              <li class="bg-primary text-lg font-medium p-2 border-round mt-2 mb-1">
                Utente
              </li>
              <li class="p-2 font-medium">
                {{ user?.fullName }} ({{ user?.email }})
              </li>
              <li class="bg-primary text-lg font-medium p-2 border-round mt-4 mb-1">
                Azienda
              </li>
              <li class="font-medium p-2">
                {{ user?.company!.name }} - {{ user?.company!.address }}
              </li>

              <li class="bg-primary text-lg font-medium p-2 border-round mt-2 mb-1">
                Ultimo accesso
              </li>
              <li class="p-2 font-medium">
                {{ formatDataIT(user?.lastAccess!) }}
              </li>
            </ul>

            <div class="flex align-items-center my-5 border-0 right justify-content-start justify-content-between">
              <!--  <a class="cursor-pointer flex flex-column flex-grow-1 align-items-center gap-2 border-1">
                <div
                  class="surface-100 hover:surface-200 transition-colors transition-duration-150 p-3 border-round-2xl p-ripple"
                  data-pd-ripple="true"
                ><i class="pi pi-comment text-xl text-600" />
                </div><span class="text-sm font-medium text-1">Assistenza</span>
              </a> -->
              <!-- <a class="cursor-pointer flex flex-column flex-grow-1 align-items-center gap-2">
                <div
                  class="surface-100 hover:surface-200 transition-colors transition-duration-150 p-3 border-round-2xl p-ripple"
                  data-pd-ripple="true"
                ><i class="pi pi-phone text-xl text-600" />
                </div>
                <span class="text-sm font-medium text-1">Call</span>
              </a> -->
              <div class="flex flex-column">
                <div class="flex align-items-center border-round-xl justify-content-center  bg-primary-reverse  font-bold  p-2">
                  <Icon name="bx:support" size="45" class="text-color" />
                </div>
                <div class="flex align-items-center justify-content-center text-color font-bold border-round mt-1">
                  <span class=" text-base">Assistenza</span>
                </div>
              </div>
              <div class="flex flex-column">
                <a v-posthog-capture="'cta_logout'" class="cursor-pointer" @click="useCompanyStore().logout()">
                  <div class="flex align-items-center justify-content-center bg-primary border-circle  font-bold p-2">
                    <!-- <Button
                    rounded
                    size="large"
                    class=" h-4rem w-auto"

                    @click="useCompanyStore().logout()"
                  /> -->
                    <Icon name="line-md:log-out" size="40" class="text-white p-2" />
                  </div>

                  <div class="flex align-items-center justify-content-center text-primary font-bold border-round mt-1">
                    <span class="text-1 text-base">Logout</span>
                  </div>
                </a>
              </div>
            </div>
            <ul v-if="appConfig.inDevelopment" class="list-none p-0 m-0">
              <li class="surface-100 text-sm text-1 font-medium p-2 border-round mt-4 mb-1">
                Permissions
              </li>
              <li>
                <span class="text-sm">{{ user?.roles[0].description }}</span>
              </li>
              <li class="text-3 p-2">
                <ul class="list-none p-0 m-0">
                  <li v-for="(item, index) in user?.permissions" :key="index" class="text-xl py-2 border-bottom-1 surface-border">
                    <div class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple">
                      <div>
                        <!-- <span class="block text-3 mb-1">INPS: {{ GetCCNL(item)?.codice_INPS }}</span> -->
                        <p class="m-0 text-color-900 font-medium text-base">
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
